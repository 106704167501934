@import "~react-international-phone/style.css";
@import "~swiper/css";

/* tailwind */
@import "./output.css";

:root {
  --primary: oklch(var(--p) / 1);
  --error: oklch(var(--er) / 1);
  --field-bg: #efedf1;
  --btn-text-case: capitalize;
  --react-international-phone-height: 3rem;
  --react-international-phone-border-radius: var(--rounded-btn);
  --react-international-phone-dropdown-item-height: 38px;
  --react-international-phone-background-color: var(--field-bg);
  --react-international-phone-font-size: 1rem;
}

html {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
  overflow-x: hidden;
}

* {
  font-family: var(--basier-circle);
}

/* scroll-bar */
* {
  &::-webkit-scrollbar {
    @apply hidden w-5 sm:block;

    &-track {
      @apply bg-transparent;
    }

    &-thumb {
      @apply rounded-[20px] border-[6px] border-solid border-transparent bg-gray-300 bg-clip-content;

      &:hover {
        @apply bg-gray-400;
      }
    }
  }
}

.input,
.select,
.textarea,
.react-international-phone-input {
  @apply w-full border border-neutral-300 bg-[var(--field-bg)] p-3 font-inter text-base font-normal text-zinc-900 ring-1 ring-slate-100/50;
  /* @apply outline-1 outline-offset-0 outline-primary #{!important}; */
  outline-width: 1px !important;
  outline-offset: 0px !important;
  outline-color: var(--primary) !important;
}

.textarea {
  @apply p-5;
}

.btn {
  @apply text-base;
}

.table :where(thead, tfoot) {
  @apply text-[13px] text-neutral-600;
}

.swiper-slide {
  width: auto;
}

.form-control {
  .label {
    @apply px-0 first:pt-0 last:pb-0;

    &:has(> span:empty) {
      display: none;
    }

    .label-text {
      @apply flex items-center gap-2 text-sm font-semibold;
      & > svg {
        width: 20px;
      }
    }

    .label-text-alt {
      @apply text-neutral-600;
    }
  }

  &[data-error="true"] {
    .input,
    .select,
    .textarea {
      outline: auto;
      outline-color: var(--error) !important;
    }

    .label-text-alt {
      @apply text-error;
    }
  }
}

.collapse-arrow > .collapse-title:after {
  @apply text-zinc-500 dark:text-neutral-400;

  inset-inline-end: 2rem;

  @media (min-width: 768px) {
    height: 0.6rem;
    width: 0.6rem;
    inset-inline-end: 2.5rem;
  }
}

.book-flight-steps {
  .step {
    &:before {
      height: 0.125rem;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    &:after {
      @apply h-5 w-5 md:h-6 md:w-6;
    }

    &[data-content]:after {
      color: white;
    }

    &-disabled {
      @apply text-neutral-300;
    }
  }

  .step-primary:after {
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
  }
}

.flight-airport-steps {
  grid-auto-rows: auto;
  gap: 5px;

  .step {
    @apply min-h-[1rem];

    &:before {
      --height: 130%;
      @apply h-[var(--height)] w-0 -translate-y-[60%] border-l border-dashed border-neutral-400 bg-transparent;
    }

    &:after {
      @apply h-5 w-5 border border-neutral-400 bg-gray-200;
    }
  }
}

.step-icon-tick:after {
  @apply bg-[url(/icons/tick.svg)] bg-[length:10px] bg-center bg-no-repeat sm:bg-[length:13px];
}

.step-icon-dot:after {
  @apply bg-[url(/icons/dot.svg)] bg-[length:8px] bg-center bg-no-repeat;
}

.react-international-phone-country-selector-button {
  background-color: var(--field-bg);
  margin-right: 0 !important;
  border-right: 0 !important;
  padding: 8px;
  img {
    margin: 0 6px;
  }
}

.react-international-phone-country-selector-dropdown {
  top: 52px !important;
  width: 310px !important;
  min-height: 250px !important;

  @apply rounded-lg bg-white shadow-lg;
}

/* .react-international-phone-input {
  border: 1px !important;
  &:focus {
    outline-style: solid !important;
    outline-width: 2px !important;
    outline-offset: 0 !important;
    outline-color: hsl(var(--p)) !important;
  }
} */

.home-steps-timeline.timeline-vertical {
  @apply gap-20 md:gap-0;

  .timeline-start,
  .timeline-end {
    margin: 0 !important;
  }

  :where(.timeline-vertical > li) {
    --timeline-row-start: minmax(0, auto);
    --timeline-row-end: minmax(0, 1fr);
    justify-items: center;
  }

  :where(.timeline > li) {
    @apply flex flex-col items-start md:grid md:items-center;
  }
}

.divider {
  @apply my-0 h-auto;

  &::before,
  &::after {
    @apply h-[1px] bg-[#C5C6D0];
  }
}

.whatsapp-widget {
  @apply fixed bottom-7 right-6 z-50 block;

  svg {
    @apply h-12 w-12 md:h-[60px] md:w-[60px];
  }
}

.chaty-animation {
  -webkit-animation: shockwaveJump 1s ease-out infinite;
  animation: shockwaveJump 1s ease-out infinite;
}

@keyframes shockwaveJump {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
  }
  50% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  55% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  60% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.booking-layout {
  @apply grid h-[100dvh] grid-rows-[auto_minmax(0,_1fr)_auto];

  main {
    @apply overflow-y-auto overflow-x-hidden;
  }

  footer {
    @apply border-t border-neutral-300 bg-gray-200 p-4;

    div.footer-content {
      @apply mx-auto flex max-w-5xl items-center justify-between;
    }
  }
}

.booking-header {
  @apply border-b border-neutral-300 bg-gray-200 p-5;

  .header-content {
    @apply mx-auto flex max-w-5xl items-center justify-between;
  }

  .logo-link {
    svg {
      @apply w-44 text-zinc-500 md:w-48;
    }
  }

  .close-link.btn {
    @apply h-10 gap-1 rounded-3xl border-2 font-normal;
    svg {
      @apply w-6;
    }
  }
}

.madal-box-otr {
  .modal {
    .modal-box {
      overflow: visible;
    }
  }
}
